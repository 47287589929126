@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,500;1,200;1,300;1,400;1,600;1,700&display=swap");

@font-face {
    font-family: hynitrfont;
    src: url(../fonts/Montserrat-Bold.ttf);
    font-weight: bold;
  }
  
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    box-sizing: border-box;
    scroll-behavior: smooth;
    transition: 0.5s ease-in;
  }
  
  
  @media (prefers-reduced-motion: no-preference) {
    :root {
      scroll-behavior: smooth;
    }
  }
  
  
  body {
  
    background-color: #fdfeff;
    color: #161421;
  }
  
  .btn-dark {
    background-color: #7ec3f7 !important;
    color: #000 !important;
    border: none !important;
  }

  .navbar-nav a {
    font-size: 18px !important;
  }

  .navbar-toggler {

    margin-top: 6% !important;
  }
  
  .btn-dark:focus{
  
      outline: none !important;
      border: 1px solid #9694e7 !important;
      box-shadow: none !important;
  
  }
  
  .btn-dark:hover {
  
    background-color: transparent !important;
    color: #161421 !important;
    border: 1px solid #9694e7 !important;
  
  }

  .mobilenav {

    background-color: #dff3ff !important;
    border-radius: 20px 20px 0px 0px;
    box-shadow: rgba(126,195,247, 0.2) 0px 8px 24px;
  }

  .mobilenav p {

    font-size: 12px;
    margin-bottom: 0% !important;
  }
  
  .invalid-feedback,
  .empty-feedback {
    display: none;
  }
  
  .was-validated :placeholder-shown:invalid ~ .empty-feedback {
    display: block;
  }
  
  .was-validated :not(:placeholder-shown):invalid ~ .invalid-feedback {
    display: block;
  }
  
  .is-invalid,
  .was-validated :invalid {
    border-color: #dc3545;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .modal-header {
    border-bottom: 0 none !important;
}

.modal-footer {
    border-top: 0 none !important;
} 
  
  #home {
  
    height: 100vh;
  }
  .search form{
      margin-top: 30px;
        width: 400px;
        max-width: 100%;
        position: relative;
    
    }
    .mldl form input:first-child{
      border: 2px solid #7ec3f7 ;
      outline: none;
      border-radius: 10px;
  }
     .mldl form input:last-child{
      outline: none;
      border: none !important;
  }
    .search form input:first-child{
        display: inline-block;
        width: 100%;
        padding: 14px 130px 14px 15px;
        border: 2px solid #7ec3f7 ;
        outline: none;
        border-radius: 10px;
    }
    .search form input:last-child{
        padding: 10px;
        display: inline-block;
        outline: none;
        border: none !important;
        position: absolute;
        border-radius: 10px;
        background-color: #7ec3f7 ;
        color: #ffffff;
        font-weight:600;
        top: 6px;
        right: 6px;
    }
  #home .card {
  
    border-radius: 20px !important;
    color: #000;
    background-color: transparent !important;
    box-shadow: rgba(126,195,247, 0.2) 0px 8px 24px;
    border: none !important;
  }
  
  #home img{
  
    transform: scale(1.2);
  }
  
  
  .card {
  
    border-radius: 20px !important;
    color: #000;
    background-color: transparent !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: none !important;
  }
  
  .card i {
  
    font-size: 3rem !important;
    color: #7ec3f7;
  }
  
  .single-price-plan {
  
    background-color: #dff3ff;
  }
  
  .pricing-plan-title {
  
    background-color: #585868;
    color: #fff;
  }
  
  #home i {
  
    font-size: 1.3rem !important;
  }
  
  p {
    margin-top: 0;
    margin-bottom: 0;
    font-family: "inter", sans-serif;
    line-height: 2;
  }
  
  h1, h2, h3,h4, h5, h6 {
    font-family: hynitrfont !important;
  }
  .navbar-brand img{
    width: 2.3rem !important;
  }
  .navbar {
    background-color: #fdfeff;
    color: #000;
    height: 12vh;
    
  }
  
  .nav-item{
    color: #000 !important;
  }
  
  
  .offcanvas {
    background-color: #fdfeff;
  }
  
  .dropdown-menu {
    background-color: #000000 !important;
    border: none !important;
  }
  span .navbar-toggler-icon {
    background-color: #000000 !important;
  }
  .navbar-toggler {
    width: 20px;
    height: 20px;
    position: relative;
    transition: 0.5s ease-in-out;
  }
  
  .toggler-icon {
    width: 100%;
    height: 2px;
    background-color: #000000;
    display: block;
    position: absolute;
    border-radius: 1px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    border-radius: 10px;
    margin-right: 100px !important;
  }
  
  /*when navigation is clicked*/
  .navbar-toggler .top-bar {
    margin-top: 7px;
    transform: rotate(0deg);
  }
  .navbar-toggler .middle-bar {
    margin-top: 13.5px;
    transform: rotate(0deg);
  }
  .navbar-toggler .bottom-bar {
    margin-top: 0px;
    transform: rotate(0deg);
  }
  /*state when navbar is collapsed*/
  .offcanvas-header .close {
    color: #7ec3f7  !important;
    font-size: 30px !important;
  }
  .nav-item {
    margin-left: 20px !important;
    
  }
  .nav-link {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
  }
  
  .footer {
    color: #000;
  }
  
  .fotlogo {
  
    width: 2rem !important;
  }
  
  hr {
  
    outline: #7ec3f7;
    color: #000;
    border: 2px soldi #000;
  }
  
  
  .confetti {
    width: 1rem;
    height: 1rem;
    display: inline-block;
    position: absolute;
    top: -1rem;
    left: 0;
    z-index: 150;
  }
  .confetti .rotate {
    animation: driftyRotate 1s infinite both ease-in-out;
    perspective: 1000;
  }
  .confetti .askew {
    background: currentColor;
    transform: skewY(10deg);
    width: 1rem;
    height: 1rem;
    animation: drifty 1s infinite alternate both ease-in-out;
    perspective:1000;
  }
    
  .confetti:nth-of-type(5n) {
    color: #F56620;
  }
  .confetti:nth-of-type(5n+1) {
    color: #00EAFF;
  }
  .confetti:nth-of-type(5n+2) {
    color: #EA8EE0;
  }
  .confetti:nth-of-type(5n+3) {
    color: #EBFF38;
  }
  .confetti:nth-of-type(5n+4) {
    color: #7ec3f7 ;
  }
  
  .confetti:nth-of-type(7n) .askew {
    animation-delay: -.6s;
    animation-duration: 2.25s;
  }
  .confetti:nth-of-type(7n + 1) .askew {
    animation-delay: -.879s;
    animation-duration: 3.5s;
  }
  .confetti:nth-of-type(7n + 2) .askew {
    animation-delay: -.11s;
    animation-duration: 1.95s;
  }
  .confetti:nth-of-type(7n + 3) .askew {
    animation-delay: -.246s;
    animation-duration: .85s;
  }
  .confetti:nth-of-type(7n + 4) .askew {
    animation-delay: -.43s;
    animation-duration: 2.5s;
  }
  .confetti:nth-of-type(7n + 5) .askew {
    animation-delay: -.56s;
    animation-duration: 1.75s;
  }
  .confetti:nth-of-type(7n + 6) .askew {
    animation-delay: -.76s;
    animation-duration: 1.5s;
  }
    
  .confetti:nth-of-type(9n) .rotate {
    animation-duration: 2s;
  }
  .confetti:nth-of-type(9n + 1) .rotate {
    animation-duration: 2.3s;
  }
  .confetti:nth-of-type(9n + 2) .rotate {
    animation-duration: 1.1s;
  }
  .confetti:nth-of-type(9n + 3) .rotate {
    animation-duration: .75s;
  }
  .confetti:nth-of-type(9n + 4) .rotate {
    animation-duration: 4.3s;
  }
  .confetti:nth-of-type(9n + 5) .rotate {
    animation-duration: 3.05s;
  }
  .confetti:nth-of-type(9n + 6) .rotate {
    animation-duration: 2.76s;
  }
  .confetti:nth-of-type(9n + 7) .rotate {
    animation-duration: 7.6s;
  }
  .confetti:nth-of-type(9n + 8) .rotate {
    animation-duration: 1.78s;
  }
  
  @keyframes drifty {
    0% {
      transform: skewY(10deg) translate3d(-250%, 0, 0);
    }
    100% {
      transform: skewY(-12deg) translate3d(250%, 0, 0);
    }
  }
  @keyframes driftyRotate {
    0% {
      transform: rotateX(0);
    }
    100% {
      transform: rotateX(359deg);
    }
  }
  
  
  
  .dm i{
  
    font-size: 2rem !important;
  }
  
  
  .modal-header {
    border-bottom: 0 none;
  }
  
  .modal-footer {
    border-top: 0 none;
  }
  
  
  /********** RESPONSIVENESS **********/
  
  /* For 480 Resolution - Laptop*/
  @media(min-width: 320px) and (max-width: 480px) {
    /* STYLES GO HERE */
  .navbar {
      height: 8vh; 
  }
  #home img{
    transform: none;
  }
  
  }
  /* For 640 Resolution - mobile */
  @media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  
    
  
    .navbar {
      height: 8vh;
      
    }
    #home img{
      transform: none;
    }
    
  
  }
  
  /* For 1024 Resolution - laptop 1 */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
   
  }
  
  /* For 1366 Resolution  -- laptop 2*/
  @media only screen and (min-width: 1030px) and (max-width: 1366px) {
    /* STYLES GO HERE */
  }
  
  /** desktop screen */
  @media only screen and (min-width: 1370px) and (max-width: 1605px) {
    /* STYLES GO HERE */
  }
  
  /*Ipad Orientation : Landscape */
  @media only screen and (orientation: landscape) and (min-device-pixel-ratio: 1) and (min-device-width: 768px) and (max-device-width: 1007px) {
    /* STYLES GO HERE */
  }
  
  /*ipad Orientation : Portrait */
  @media only screen and (orientation: portrait) and (min-device-pixel-ratio: 1) and (min-device-width: 768px) and (max-device-width: 1007px) {
    /* STYLES GO HERE */
  }
  
  